#main {
    padding: 0px;
}

/* .body {

    background-color: lightblue;
} */

#tc {
    margin-top: 10px;
    float: right;
    margin-right: 4px;
}

#photo {
    width: 50px;
}

.page {
    position: absolute;
    right: 0;
    bottom: -39px;
}

#search {
    margin-top: 22px;
}

.add {
    margin-top: 24px;
    margin-right: 5px;
}

#major {
    margin-right: 64%;
    margin-top: 10px;
}

#card {
    text-align: center;

}

.para {
    background-color: #fff3cd;

}

.stud {
    float: inline-start;
}